var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-item-container",class:{ 'disabled': _vm.isTabDisabled }},[_c('div',{staticClass:"tab-item-container__block"},[_c('span',{staticClass:"tab-item-container__block-title"},[_vm._v(" "+_vm._s(_vm.$t('Main.CustomizeColumnsSubmenus.ReplenishmentParams'))+" ")]),_c('div',{staticClass:"tab-item-container__block-row"},[_c('div',{staticClass:"tab-item-container__label"},[_vm._v(" "+_vm._s(_vm.defaultLeadTimeLabel)+" ")]),_c('div',{staticClass:"tab-item-container__block-row"},[_c('SlValidate',{attrs:{"vid":"defaultLeadTimeModel","rules":"required|between:0,9999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"is-invalid":invalid,"min":0,"max":9999},on:{"change":function($event){return _vm.handleChange('inventory', 'defaultLeadTime', $event)}},model:{value:(_vm.defaultLeadTimeModel),callback:function ($$v) {_vm.defaultLeadTimeModel=$$v},expression:"defaultLeadTimeModel"}})]}}])})],1)]),_c('div',{staticClass:"tab-item-container__block-row"},[_c('div',{staticClass:"tab-item-container__label"},[_vm._v(" "+_vm._s(_vm.defaultOrderCycleLabel)+" ")]),_c('div',{staticClass:"tab-item-container__block-row"},[_c('SlValidate',{attrs:{"vid":"defaultOrderCycleModel","rules":"required|between:1,99"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"is-invalid":invalid,"min":1,"max":99,"disabled":_vm.isDefaultOrderCycleDisabled},on:{"change":function($event){return _vm.handleChange('inventory', 'defaultOrderCycle', $event)}},model:{value:(_vm.defaultOrderCycleModel),callback:function ($$v) {_vm.defaultOrderCycleModel=$$v},expression:"defaultOrderCycleModel"}})]}}])})],1)]),_c('div',{staticClass:"tab-item-container__block-row"},[_c('div',{staticClass:"tab-item-container__label"},[_vm._v(" "+_vm._s(_vm.shelfDiscardLabel)+" ")]),_c('div',{staticClass:"tab-item-container__block-row"},[_c('SlValidate',{attrs:{"vid":"shelfDiscardModel","rules":"required|between:1,99"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"is-invalid":invalid,"min":1,"max":99},on:{"change":function($event){return _vm.handleChange('inventory', 'shelfDiscard', $event)}},model:{value:(_vm.shelfDiscardModel),callback:function ($$v) {_vm.shelfDiscardModel=$$v},expression:"shelfDiscardModel"}})]}}])})],1)])]),_c('div',{staticClass:"tab-item-container__block"},[_c('span',{staticClass:"tab-item-container__block-title"},[_vm._v(" "+_vm._s(_vm.$t('WiInventorySettings.Ui.grpColumns'))+" ")]),_c('SlCheckbox',{staticClass:"sl-checkbox",attrs:{"label":_vm.$t('WiInventorySettings.Ui.chkDemandMaterial')},model:{value:(_vm.demandViewModel),callback:function ($$v) {_vm.demandViewModel=$$v},expression:"demandViewModel"}}),_c('div',{staticClass:"tab-item-container__block-row"},[_c('div',{staticClass:"tab-item-container__label"},[_vm._v(" "+_vm._s(_vm.$t('Settings.Inventory.lbActual'))+" ")]),_c('SlValidate',{attrs:{"vid":"actualSalesModel","rules":"required|between:0,99"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"is-invalid":invalid,"min":0,"max":99},on:{"change":function($event){return _vm.handleChange('nActual', null, $event)}},model:{value:(_vm.nActualModel),callback:function ($$v) {_vm.nActualModel=$$v},expression:"nActualModel"}})]}}])})],1)],1),_c('div',{staticClass:"tab-item-container__block"},[_c('span',{staticClass:"tab-item-container__block-title"},[_vm._v(" "+_vm._s(_vm.$t('WiInventorySettings.Ui.grpPlan'))+" ")]),_c('div',{staticClass:"tab-item-container__block-row"},[_c('span',{staticClass:"tab-item-container__label"},[_vm._v(" "+_vm._s(_vm.$t('Settings.Inventory.lbType'))+" ")]),_c('SlControl',{attrs:{"options":_vm.orderStrategyOptions},model:{value:(_vm.orderStrategyModel),callback:function ($$v) {_vm.orderStrategyModel=$$v},expression:"orderStrategyModel"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }