var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-item-container",class:{ 'disabled': _vm.disabled }},[_c('div',{staticClass:"tab-item-container__block"},[_c('span',{staticClass:"tab-item-container__block-title"},[_vm._v(" "+_vm._s(_vm.$t('Settings.Ui.grpForecast'))+" ")]),_c('div',{staticClass:"tab-item-container__block-row"},[_c('div',{staticClass:"tab-item-container__label"},[_vm._v(" "+_vm._s(_vm.horizonLabel)+" ")]),_c('SlValidate',{attrs:{"vid":"horizonModel","rules":"required|between:1,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"is-invalid":invalid,"min":1,"max":100},on:{"change":function($event){return _vm.handleChange('horizon', null, $event)}},model:{value:(_vm.horizonModel),callback:function ($$v) {_vm.horizonModel=$$v},expression:"horizonModel"}})]}}])})],1),_c('div',{staticClass:"tab-item-container__block-row"},[_c('div',{staticClass:"tab-item-container__label"},[_vm._v(" "+_vm._s(_vm.$t('Settings.Ui.lbIsSeasonal'))+" ")]),_c('SlValidate',{attrs:{"vid":"seasonalThresholdModel","rules":"required|between:0,1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"is-invalid":invalid,"min":0,"max":1,"step":0.05,"fixed":2},on:{"change":function($event){return _vm.handleChange( 'seasonalTreshold', null, $event)}},model:{value:(_vm.seasonalThresholdModel),callback:function ($$v) {_vm.seasonalThresholdModel=$$v},expression:"seasonalThresholdModel"}})]}}])})],1),_c('div',{staticClass:"tab-item-container__block-row"},[_c('div',{staticClass:"tab-item-container__label"},[_vm._v(" "+_vm._s(_vm.$t('Settings.Ui.lbPriceElasticityThreshold'))+" ")]),_c('SlValidate',{attrs:{"vid":"elasticityThresholdModel","rules":"required|between:-1,0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"is-invalid":invalid,"min":-1,"max":0,"step":0.01,"fixed":2},on:{"change":function($event){return _vm.handleChange('elasticityThreshold', null, $event)}},model:{value:(_vm.elasticityThresholdModel),callback:function ($$v) {_vm.elasticityThresholdModel=$$v},expression:"elasticityThresholdModel"}})]}}])})],1),_c('div',{staticClass:"tab-item-container__block-row"},[_c('div',{staticClass:"tab-item-container__label"},[_vm._v(" "+_vm._s(_vm.$t('Settings.Ui.lbMassTopDown'))+" ")]),_c('SlDropdown',{staticClass:"tab-item-container__select",attrs:{"target-label":_vm.$t(("Settings.MassTd." + _vm.massTopDownValue)),"placement":"bottom","auto-size":""},scopedSlots:_vm._u([{key:"content",fn:function(){return _vm._l((_vm.massTopDownItems),function(option){return _c('SlDropdownOption',{key:option.value,attrs:{"select-close":""},on:{"select":function($event){return _vm.setMassTopDown(option.value)}}},[_vm._v(" "+_vm._s(option.text)+" ")])})},proxy:true}])})],1),_c('div',{staticClass:"tab-item-container__block-row"},[_c('SlCheckbox',{attrs:{"label":_vm.$t('Settings.Ui.chkAllowNegativeSlope')},model:{value:(_vm.allowNegativeSlopeModel),callback:function ($$v) {_vm.allowNegativeSlopeModel=$$v},expression:"allowNegativeSlopeModel"}})],1),_c('span',{staticClass:"tab-item-container__block-sub-title"},[_vm._v(" "+_vm._s(_vm.$t('Settings.Ui.grpConfidenceLevels'))+" ")]),_c('div',{staticClass:"tab-item-container__block-row"},[_c('div',{staticClass:"tab-item-container__label"},[_vm._v(" "+_vm._s(_vm.upperConfLevelLabel)+" ")]),_c('SlValidate',{attrs:{"vid":"upperConfLevelModel","rules":"required|between:0.1,99.9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"is-invalid":invalid,"min":0.1,"max":99.9,"step":0.1,"fixed":1},on:{"change":function($event){return _vm.handleChange('upperConfLevel', null, $event)}},model:{value:(_vm.upperConfLevelModel),callback:function ($$v) {_vm.upperConfLevelModel=$$v},expression:"upperConfLevelModel"}})]}}])})],1),_c('div',{staticClass:"tab-item-container__block-row"},[_c('div',{staticClass:"tab-item-container__label"},[_vm._v(" "+_vm._s(_vm.lowerConfLevelLabel)+" ")]),_c('div',{staticClass:"tab-item-container__block-row"},[_c('SlValidate',{attrs:{"vid":"lowerConfLevelModel","rules":"required|between:0.1,99.9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"is-invalid":invalid,"min":0.1,"max":99.9,"step":0.1,"fixed":1},on:{"change":function($event){return _vm.handleChange('lowerConfLevel', null, $event)}},model:{value:(_vm.lowerConfLevelModel),callback:function ($$v) {_vm.lowerConfLevelModel=$$v},expression:"lowerConfLevelModel"}})]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }