<template>
  <div
    class="tab-item-container"
    :class="{ 'disabled': isTabDisabled }"
  >
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('Web.Settings.Dc.replenishmentParams') }}
      </span>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ defaultLeadTimeLabel }}
        </div>
        <SlValidate
          v-slot="{ invalid }"
          vid="defaultLeadTimeModel"
          rules="required|between:0,9999"
        >
          <SlControlInput
            v-model="defaultLeadTimeModel"
            :is-invalid="invalid"
            :min="0"
            :max="9999"
            @change="handleChange('distrib', 'defaultLeadTime', $event)"
          />
        </SlValidate>
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ defaultOrderCycleLabel }}
        </div>
        <SlValidate
          v-slot="{ invalid }"
          vid="defaultOrderCycleModel"
          rules="required|between:1,99"
        >
          <SlControlInput
            v-model="defaultOrderCycleModel"
            :is-invalid="invalid"
            :min="1"
            :max="99"
            :disabled="isDefaultOrderCycleDisabled"
            @change="handleChange('distrib', 'defaultOrderCycle', $event)"
          />
        </SlValidate>
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ shelfDiscardLabel }}
        </div>
        <SlValidate
          v-slot="{ invalid }"
          vid="shelfDiscardModel"
          rules="required|between:1,99"
        >
          <SlControlInput
            v-model="shelfDiscardModel"
            :is-invalid="invalid"
            :min="1"
            :max="99"
            @change="handleChange('distrib', 'shelfDiscard', $event)"
          />
        </SlValidate>
      </div>
    </div>
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('WiInventorySettings.Ui.grpPlan') }}
      </span>
      <div class="tab-item-container__block-row">
        <span class="tab-item-container__label">
          {{ $t('Settings.Inventory.lbType') }}
        </span>
        <SlControl
          v-model="orderStrategyModel"
          :options="orderStrategyOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  orderCycleUnitLabelByValue,
  orderCycleValues,
  orderStrategyKeys,
  orderStrategyOptions
} from '@/config/shared/settings.config';

export default {
  name: 'DCTab',
  props: {
    disabled: Boolean
  },
  data() {
    return {
      orderStrategyKeys
    };
  },
  computed: {
    ...mapState('settings', [
      'settings'
    ]),
    ...mapGetters({
      hasInventory: 'project/hasInv',
      hasDistribution: 'project/hasDistrib',
      period: 'project/period'
    }),
    orderCycleUnitLabelByValue() {
      return orderCycleUnitLabelByValue(this.period, this);
    },
    orderCycleUnitValue() {
      return this.settings?.orderCycleUnit;
    },
    orderCycleUnitLabel() {
      return this.orderCycleUnitLabelByValue[this.orderCycleUnitValue];
    },
    isTabDisabled() {
      return this.disabled || !this.hasInventory || !this.hasDistribution;
    },
    isDefaultOrderCycleDisabled() {
      return this.orderCycleUnitValue === orderCycleValues.LEADTIME;
    },
    defaultLeadTimeLabel() {
      return `${this.$t('WiInventorySettings.Ui.lbLeadTime')}, ${this.$t('WiInventorySettings.Ui.lbLeadTime2')}`;
    },
    defaultOrderCycleLabel() {
      return `${this.$t('WiInventorySettings.Ui.lbOrderCycle')}, ${this.orderCycleUnitLabel}`;
    },
    shelfDiscardLabel() {
      return `${this.$t('WiInventorySettings.Ui.lbDiscard')}, %`;
    },
    orderStrategyOptions() {
      return orderStrategyOptions(this);
    },
    defaultLeadTimeModel: {
      get() {
        return this.settings?.distrib?.defaultLeadTime;
      },
      set(value) {
        this.settings.distrib.defaultLeadTime = value;
      }
    },
    defaultOrderCycleModel: {
      get() {
        return this.settings?.distrib?.defaultOrderCycle;
      },
      set(value) {
        this.settings.distrib.defaultOrderCycle = value;
      }
    },
    shelfDiscardModel: {
      get() {
        return this.settings?.distrib?.shelfDiscard;
      },
      set(value) {
        this.settings.distrib.shelfDiscard = value;
      }
    },
    orderStrategyModel: {
      get() {
        return this.settings?.distrib?.orderStrategy;
      },
      set(value) {
        this.changeSettings({
          key: 'distrib',
          subKey: 'orderStrategy',
          value
        });
      }
    }
  },
  methods: {
    ...mapActions('settings', [
      'changeSettings'
    ]),
    handleChange(key, subKey, value) {
      this.changeSettings({ key, subKey, value: +value });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/settings";
</style>