<template>
  <div
    class="tab-item-container"
    :class="{ 'disabled': disabled }"
  >
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title grey-90">
        {{ $t('Settings.Abc.criterionAbc') }}
      </span>
      <div class="tab-item-container__block-row">
        <span class="tab-item-container__label tab-item-container__label--control">
          {{ $t('Settings.Abc.criterionType') }}
        </span>
        <SlSelect
          v-model="abcFuncValue"
          :options="abcAnalysisFuncItems"
          class="tab-item-container__select"
          track-by="value"
          label="text"
        />
      </div>
      <transition-group
        v-if="!abcHidden"
        class="tab-item-container__block-column tab-item-container__column-folded"
        name="fade-down"
      >
        <div
          v-if="abcSumFunctionSelected"
          key="typesSelection"
          class="tab-item-container__block-row"
        >
          <div class="tab-item-container__label tab-item-container__label--control">
            {{ $t('WiAbc.Ui.lbAbcNumber') }}
          </div>
          <SlControl
            :value="abcTypeValue"
            :options="abcTypesOptions"
            @input="(value) => setAbcValue('type', value)"
          />
        </div>
        <div
          key="classes"
          class="tab-item-container__block-column tab-item-container__analysis-classes-column tab-item-container__column-folded"
        >
          <span 
            v-if="abcSumFunctionSelected"
            class="tab-item-container__label tab-item-container__label--criteria"
          >
            {{ $t('Settings.Abc.classesLabel') }}
          </span>
          <ValidationObserver ref="abcValidationObserver">
            <transition-group
              class="tab-item-container__block-row"
              name="fade-down"
            >
              <div
                v-for="item in abcClassItems"
                :key="item.label"
                :class="[{
                  'tab-item-container__block-column--abc': !abcSumFunctionSelected && item.type === 'input',
                  'tab-item-container__block-column--rest-value': !abcSumFunctionSelected && item.type === 'text'
                }, 'tab-item-container__block-column']"
              >
                <template v-if="item.type === 'input'">
                  <div class="tab-item-container__label tab-item-container__label--criteria">
                    {{ `${item.label}, %:` }}
                  </div>
                  <div class="tab-item-container__block-row">
                    <div
                      v-if="!abcSumFunctionSelected"
                      class="tab-item-container__label--range grey-80"
                    >
                      {{ `${item.minValue - 1}% ` }}
                    </div>
                    <ValidationProvider
                      v-slot="{ invalid }"
                      :ref="`${item.key}-provider`"
                      :name="item.label"
                      :rules="getAbcXyzRules('abc', item)"
                      immediate
                      class="tab-item-container__validation-provider tab-item-container__validation-provider--abc"
                    >
                      <SlControlInput
                        v-model="item.value"
                        :min="item.minValue"
                        :is-invalid="invalid"
                        @input="(value) => handleCounterInput(item.key, value)"
                      />
                      <span
                        v-show="getAbcXyzErrors('abcValidationObserver', ['more', 'less', 'required'], item.label)"
                        key="errorLabel"
                        class="tab-item-container__error-wrapper--control"
                      >
                        {{ getAbcXyzErrors('abcValidationObserver', ['more', 'less', 'required'], item.label) }}
                      </span>
                    </ValidationProvider>
                  </div>
                </template>
                <template v-else>
                  <div class="tab-item-container__label tab-item-container__label--criteria">
                    {{ item.label }}
                  </div>
                  <div 
                    :class="[{
                      'tab-item-container__label--rest-value': !abcSumFunctionSelected
                    }, 'tab-item-container__label tab-item-container__label--abc']"
                  >
                    <template v-if="abcSumFunctionSelected">
                      {{ item.value }} %
                    </template>
                    <template v-else>
                      {{ `> ${item.minValue - 1}%` }}
                    </template>
                  </div>
                </template>
              </div>
            </transition-group>
            <transition name="fade-down">
              <div
                v-show="getAbcXyzErrors('abcValidationObserver', [$t('Settings.Abc.sumError')])"
                key="errorLabel"
                class="tab-item-container__error-wrapper"
              >
                {{ getAbcXyzErrors('abcValidationObserver', [$t('Settings.Abc.sumError')]) }}
              </div>
            </transition>
          </ValidationObserver>
        </div>
      </transition-group>
    </div>
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title grey-90">
        {{ $t('Settings.Abc.criterionXyz') }}
      </span>
      <div class="tab-item-container__block-row">
        <span class="tab-item-container__label tab-item-container__label--control">
          {{ $t('Settings.Abc.criterionType') }}
        </span>
        <SlSelect
          v-model="xyzFuncValue"
          :options="abcAnalysisFuncItems"
          :disabled="abcHidden"
          class="tab-item-container__select tab-item-container__select--enlarged"
          track-by="value"
          label="text"
        />
      </div>
      <ValidationObserver ref="xyzValidationObserver">
        <transition-group
          v-if="!xyzHidden"
          key="classItems"
          name="fade-down"
          class="tab-item-container__block-column tab-item-container__column-folded"
        >
          <div
            v-if="xyzSumFunctionSelected"
            key="typesSelection"
            class="tab-item-container__block-row"
          >
            <div class="tab-item-container__label tab-item-container__label--control">
              {{ $t('WiAbc.Ui.lbAbcNumber') }}
            </div>
            <SlControl
              :value="xyzTypeValue"
              :options="abcTypesOptions"
              @input="(value) => setXyzValue('type', value)"
            />
          </div>
          <div
            key="classes"
            class="tab-item-container__block-column tab-item-container__analysis-classes-column tab-item-container__column-folded"
          >
            <span
              v-if="xyzSumFunctionSelected"
              key="classesLabel"
              class="tab-item-container__label tab-item-container__label--criteria"
            >
              {{ $t('Settings.Abc.classesLabel') }}
            </span>
            <transition-group
              class="tab-item-container__block-row"
              name="fade-down"
            >
              <div
                v-for="item in xyzClassItems"
                :key="item.label"
                :class="[{
                  'tab-item-container__block-column--abc': !xyzSumFunctionSelected && item.type === 'input',
                  'tab-item-container__block-column--rest-value': !xyzSumFunctionSelected && item.type === 'text'
                }, 'tab-item-container__block-column']"
              >
                <template v-if="item.type === 'input'">
                  <div class="tab-item-container__label--criteria">
                    {{ `${item.label}, %:` }}
                  </div>
                  <div class="tab-item-container__block-row">
                    <div
                      v-if="!xyzSumFunctionSelected"
                      class="tab-item-container__label--range"
                    >
                      {{ `${item.minValue - 1}% ` }}
                    </div>
                    <ValidationProvider
                      v-slot="{ invalid }"
                      :ref="`${item.key}-between-provider`"
                      :name="item.label"
                      :rules="getAbcXyzRules('xyz', item)"
                      immediate
                      class="tab-item-container__validation-provider tab-item-container__validation-provider--abc"
                    >
                      <SlControlInput
                        v-model="item.value"
                        :min="item.minValue"
                        :is-invalid="invalid"
                        @input="(value) => handleCounterInput(item.key, value)"
                      />
                      <span
                        v-show="getAbcXyzErrors('xyzValidationObserver', ['more', 'less', 'required'], item.label)"
                        key="errorLabel"
                        class="tab-item-container__error-wrapper--control"
                      >
                        {{ getAbcXyzErrors('xyzValidationObserver', ['more', 'less', 'required'], item.label) }}
                      </span>
                    </ValidationProvider>
                  </div>
                </template>
                <template v-else>
                  <div class="tab-item-container__label tab-item-container__label--criteria">
                    {{ item.label }}
                  </div>
                  <div
                    :class="[{
                      'tab-item-container__label--rest-value': !xyzSumFunctionSelected
                    }, 'tab-item-container__label tab-item-container__label--abc']"
                  >
                    <template v-if="xyzSumFunctionSelected">
                      {{ item.value }} %
                    </template>
                    <template v-else>
                      {{ `> ${item.minValue - 1}%` }}
                    </template>
                  </div>
                </template>
              </div>
            </transition-group>
            <transition name="fade-down">
              <span
                v-show="getAbcXyzErrors('xyzValidationObserver', [$t('Settings.Abc.sumError')])"
                key="errorLabel"
                class="tab-item-container__error-wrapper"
              >
                {{ getAbcXyzErrors('xyzValidationObserver', [$t('Settings.Abc.sumError')]) }}
              </span>
            </transition>
          </div>
        </transition-group>
      </ValidationObserver>
    </div>
    <SafetyStock />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  abcAnalysisSumFuncOptions,
  abcClassItems,
  abcTypesOptions,
  classItemsTypes,
  xyzClassItems,
  abcAnalysisSumKeys,
  abcClassRangeItems,
  xyzClassRangeItems,
  abcTypeKeys,
  sumErrorMessage
} from '@/config/shared/settings.config';
import { checkNegativeNum, stringToNumber } from '@/helpers/format/workWithNums';
import SafetyStock from '@/components/Settings/SafetyStock/Index.vue';

export default {
  name: 'AbcAnalysisTab',
  components: {
    SafetyStock
  },
  props: {
    disabled: Boolean
  },
  data() {
    return {
      abcAnalysisFuncItems: abcAnalysisSumFuncOptions(this),
      abcTypesOptions,
      abcSum: null,
      xyzSum: null,
      sumErrorMessage: sumErrorMessage(this),
      classMaxSum: 100,
      classMinValue: 1
    };
  },
  computed: {
    ...mapState('settings', [
      'settings'
    ]),
    abcHidden() {
      return this.abcFuncValue && this.abcFuncValue === abcAnalysisSumKeys.NONE;
    },
    xyzHidden() {
      return this.xyzFuncValue === abcAnalysisSumKeys.NONE || this.abcHidden;
    },
    abcTypeValue() {
      return this.abcSumFunctionSelected ? this.settings?.abc?.type : abcTypeKeys.ABC;
    },
    xyzTypeValue() {
      return this.xyzSumFunctionSelected ? this.settings?.xyz?.type : abcTypeKeys.ABC;
    },
    abcItemsNamesString() {
      return this.abcSelectedItems.map(item => `@${item.label}`).join(',');
    },
    xyzItemsNamesString() {
      return this.xyzSelectedItems.map(item => `@${item.label}`).join(',');
    },
    isAbcSumInvalid() {
      return this.abcSum > 100;
    },
    isXyzSumInvalid() {
      return this.xyzSum > 100;
    },
    abcSumFunctionSelected() {
      return Object.values(abcAnalysisSumKeys).includes(this.abcFuncValue);
    },
    xyzSumFunctionSelected() {
      return Object.values(abcAnalysisSumKeys).includes(this.xyzFuncValue);
    },
    abcSelectedItems() {
      const abcItems = this.abcSumFunctionSelected ? abcClassItems : abcClassRangeItems;

      return abcItems
        .slice(0, this.abcTypeValue?.length);
    },
    xyzSelectedItems() {
      const xyzItems = this.xyzSumFunctionSelected ? xyzClassItems : xyzClassRangeItems;

      return xyzItems
        .slice(0, this.xyzTypeValue?.length);
    },
    abcClassItems() {
      if (!this.abcTypeValue || this.abcHidden) {
        return;
      }

      return this.abcSelectedItems
        .map((item, index, items) => this.classItemsMapping(item, index, items, 'abc'));
    },
    xyzClassItems() {
      if (this.xyzHidden) {
        return;
      }

      return this.xyzSelectedItems
        .map((item, index, items) => this.classItemsMapping(item, index, items, 'xyz'));
    },
    abcFuncValue: {
      get() {
        return this.settings?.abc?.func;
      },
      set(value) {
        this.changeSettings({
          key: 'abc',
          subKey: 'func',
          value
        });
      }
    },
    abcAModel: {
      get() {
        return this.settings?.abc?.pcA;
      },
      set(value) {
        this.changeSettings({
          key: 'abc',
          subKey: 'pcA',
          value: stringToNumber(value)
        });
      }
    },
    abcBModel: {
      get() {
        return this.settings?.abc?.pcB;
      },
      set(value) {
        this.changeSettings({
          key: 'abc',
          subKey: 'pcB',
          value: stringToNumber(value)
        });
      }
    },
    abcCModel: {
      get() {
        return this.settings?.abc?.pcC;
      },
      set(value) {
        this.changeSettings({
          key: 'abc',
          subKey: 'pcC',
          value: stringToNumber(value)
        });
      }
    },
    abcXModel: {
      get() {
        return this.settings?.abc?.pcX;
      },
      set(value) {
        this.changeSettings({
          key: 'abc',
          subKey: 'pcX',
          value: stringToNumber(value)
        });
      }
    },
    abcYModel: {
      get() {
        return this.settings?.abc?.pcY;
      },
      set(value) {
        this.changeSettings({
          key: 'abc',
          subKey: 'pcY',
          value: stringToNumber(value)
        });
      }
    },
    xyzFuncValue: {
      get() {
        return this.settings?.xyz?.func;
      },
      set(value) {
        this.changeSettings({
          key: 'xyz',
          subKey: 'func',
          value
        });
      }
    },
    xyzAModel: {
      get() {
        return this.settings?.xyz?.pcA;
      },
      set(value) {
        this.changeSettings({
          key: 'xyz',
          subKey: 'pcA',
          value: stringToNumber(value)
        });
      }
    },
    xyzBModel: {
      get() {
        return this.settings?.xyz?.pcB;
      },
      set(value) {
        this.changeSettings({
          key: 'xyz',
          subKey: 'pcB',
          value: stringToNumber(value)
        });
      }
    },
    xyzCModel: {
      get() {
        return this.settings?.xyz?.pcC;
      },
      set(value) {
        this.changeSettings({
          key: 'xyz',
          subKey: 'pcC',
          value: stringToNumber(value)
        });
      }
    },
    xyzXModel: {
      get() {
        return this.settings?.xyz?.pcX;
      },
      set(value) {
        this.changeSettings({
          key: 'xyz',
          subKey: 'pcX',
          value: stringToNumber(value)
        });
      }
    },
    xyzYModel: {
      get() {
        return this.settings?.xyz?.pcY;
      },
      set(value) {
        this.changeSettings({
          key: 'xyz',
          subKey: 'pcY',
          value: stringToNumber(value)
        });
      }
    }
  },
  watch: {
    abcHidden(value) {
      if (value) {
        this.xyzFuncValue = abcAnalysisSumKeys.NONE;
      }
    }
  },
  methods: {
    ...mapActions('settings', [
      'changeSettings'
    ]),
    handleCounterInput(key, value) {
      this[key] = +value;
    },
    setAbcValue(subKey, value) {
      this.changeSettings({ key: 'abc', subKey, value });
    },
    setXyzValue(subKey, value) {
      this.changeSettings({ key: 'xyz', subKey, value });
    },
    classItemsMapping(item, index, items, classType) {
      const { key } = item;

      const isLastItem = index === items.length - 1;
      const isFirstItem = index === 0;
      const isSumFunction = this[`${classType}SumFunctionSelected`];
      const previousValue = this[items[index - 1]?.key];
      const minValue = !isFirstItem && !isSumFunction ? previousValue + 1 : this.classMinValue;

      if (isLastItem) {
        const allItemsValuesSum = items
          .slice(0, items.length - 1)
          .reduce(
            (acc, item) => acc + (this[item.key] || 0),
            0);
        const restValue = this.classMaxSum - allItemsValuesSum;

        this[`${classType}Sum`] = allItemsValuesSum;

        return {
          ...item,
          type: classItemsTypes.TEXT,
          value: checkNegativeNum(restValue),
          minValue
        };
      }

      return {
        ...item,
        type: classItemsTypes.INPUT,
        value: this[key],
        minValue
      };
    },
    getAbcXyzErrors(validatorKey, errorKeys, fieldKey) {
      const errors = this.$refs?.[validatorKey]?.errors;
      const isAnyError = errors ? Object.values(errors).some(errorsArray => errorsArray.length) : false;

      if (!errors || !isAnyError) {
        return;
      }

      let flattenedErrors = Object.values(errors).flat();

      if (fieldKey) {
        flattenedErrors = flattenedErrors.filter(error => error.includes(fieldKey));
      }

      return flattenedErrors.find(errorItem => new RegExp(errorKeys.join('|')).test(errorItem));
    },
    getAbcXyzRules(classType, item) {
      const baseRules = {
        'min_value': item.minValue,
        required: true
      };
      const isSumFunctionSelected = this[`${classType}SumFunctionSelected`];

      return isSumFunctionSelected
        ? {
          ...baseRules,
          'sl_abc_check_sum': this[`${classType}Sum`],
          'max_value': 99
        }
        : baseRules;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/settings";
</style>