<template>
  <div
    class="matrix-card"
    :class="cardClasses"
  >
    <div class="matrix-card__header">
      {{ label }}
      <SlCheckbox
        :value="selected"
        @change="$emit('change', $event)"
        @click.native.stop
      />
    </div>
    <div class="matrix-card__divider" />
    <div class="matrix-card__content">
      <div class="matrix-card__content-row matrix-card__content-row--content">
        <div class="matrix-card__content-column">
          <div class="matrix-card__content-row matrix-card__content-row--service-level">
            <span
              v-tooltip.bottom="getTooltip($t('WiInventorySettings.Ui.chkServiceLevel'))"
              class="matrix-card__content-row--title"
            >
              {{ $t('WiInventorySettings.Ui.chkServiceLevel') }}:
            </span>
            <span class="matrix-card__content-row--value">{{ getLevelValue(inventoryParams) }}</span>
          </div>
          <div class="matrix-card__content-row">
            <span
              v-tooltip.bottom="getTooltip($t('Web.Settings.Inventory.salesDemandLabel'))"
              class="matrix-card__content-row--title"
            >
              {{ $t('Web.Settings.Inventory.salesDemandLabel') }}:
            </span>
            <span class="matrix-card__content-row--value">{{ getParamValue(inventoryParams.futureSales) }}</span>
          </div>
          <div class="matrix-card__content-row">
            <span
              v-tooltip.bottom="getTooltip($t('Web.Settings.Inventory.lowerEchelonLabel'))"
              class="matrix-card__content-row--title"
            >
              {{ $t('Web.Settings.Inventory.lowerEchelonLabel') }}:
            </span>
            <span class="matrix-card__content-row--value">{{ getParamValue(inventoryParams.futureDc) }}</span>
          </div>
          <div
            v-if="hasBom"
            class="matrix-card__content-row"
          >
            <span
              v-tooltip.bottom="getTooltip($t('Web.Settings.Inventory.materialConsumptionLabelShort'))"
              class="matrix-card__content-row--title"
            >
              {{ $t('Web.Settings.Inventory.materialConsumptionLabelShort') }}:
            </span>
            <span class="matrix-card__content-row--value">{{ getParamValue(inventoryParams.futureBom) }}</span>
          </div>
        </div>

        <div
          v-if="hasDcParams"
          class="matrix-card__content-column matrix-card__content-column--dc"
        >
          <div class="matrix-card__content-row matrix-card__content-row--service-level">
            <span class="matrix-card__content-row--title">{{ $t('Settings.TabNames.dc') }}:</span>
            <span class="matrix-card__content-row--value">{{ getLevelValue(dcParams) }}</span>
          </div>
          <div class="matrix-card__content-row">
            <span class="matrix-card__content-row--title">{{ $t('Settings.TabNames.dc') }}:</span>
            <span class="matrix-card__content-row--value">{{ getParamValue(dcParams.futureSales) }}</span>
          </div>
          <div class="matrix-card__content-row">
            <span class="matrix-card__content-row--title">{{ $t('Settings.TabNames.dc') }}:</span>
            <span class="matrix-card__content-row--value">{{ getParamValue(dcParams.futureDc) }}</span>
          </div>
          <div
            v-if="hasBom"
            class="matrix-card__content-row"
          >
            <span class="matrix-card__content-row--title">{{ $t('Settings.TabNames.dc') }}:</span>
            <span class="matrix-card__content-row--value">{{ getParamValue(dcParams.futureBom) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTooltip } from '@/helpers/shared/tooltip';
import { mapGetters } from 'vuex';

export default {
  name: 'MatrixCard',
  props: {
    settings: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      default: '',
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      getTooltip
    };
  },
  computed: {
    ...mapGetters({
      hasDistribution: 'project/hasDistrib',
      hasBom: 'project/hasBom',
      period: 'project/period'
    }),
    cardClasses() {
      return {
        'matrix-card--selected': this.selected,
        [`matrix-card--${this.settings.color}`]: true
      };
    },
    inventoryParams() {
      return this.settings.inventoryParams;
    },
    dcParams() {
      return this.settings.dcParams;
    },
    hasDcParams() {
      return this.hasDistribution && this.settings.wantAlternativeForDC;
    }
  },
  methods: {
    getLevelValue(param) {
      return param.enableServiceLevel ? `${param.serviceLevel}%` : '—';
    },
    getParamValue(param) {
      return param.enabled
        ? `${param.val}${this.$t(`Common.AfterNumShort.${this.period}`)}`
        : '—';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/settings/matrix-card";
</style>
