<template>
  <div
    v-if="safetyStock"
    class="tab-item-container__block"
  >
    <SafetyStockEditModal :id="modalIds.SAFETY_STOCK_EDIT" />
    <div class="tab-item-container__block-row safety-stock__title">
      <span class="tab-item-container__block-title grey-90">
        {{ title }}
      </span>
      <SlSelectionInfo v-if="selectedItems.length">
        <template #description>
          {{ $t('Web.Selection', { 1: selectedItems.length }) }}
        </template>
        <template #actions>
          <SlButton
            variant="text"
            color="grey"
            @click="resetSelected"
          >
            <template #prepend>
              <icon
                class="fill-off size-20 color-grey-80"
                data="@icons/close.svg"
              />
            </template>
            {{ $t('Web.Deselect') }}
          </SlButton>
          <SlButton
            variant="secondary"
            color="grey"
            @click="handleEditSelected"
          >
            <template #prepend>
              <icon
                class="fill-off size-16 color-grey-80"
                data="@icons/pen.svg"
              />
            </template>
            {{ $t('Web.BtnEditSelected') }}
          </SlButton>
        </template>
      </SlSelectionInfo>
    </div>
    <transition
      v-if="showMatrix"
      name="fade-down"
      class="tab-item-container__block"
    >
      <transition-group
        v-if="safetyStock"
        tag="div"
        name="fade-down"
        class="safety-stock-matrix"
      >
        <transition-group
          v-for="(row, rowIndex) in safetyStock.matrix"
          :key="`row-${rowIndex}`"
          tag="div"
          name="fade-down"
          class="tab-item-container__block-row safety-stock-matrix__row"
        >
          <div
            v-if="safetyStock.nRows > 1"
            :key="`row-${rowIndex}-abcAxis`"
            class="tab-item-container__block-row safety-stock-matrix__title"
          >
            {{ abcAxis[rowIndex] }}
          </div>

          <div
            v-for="(item, colIndex) in row"
            :key="`row-${rowIndex}-col-${colIndex}`"
            class="tab-item-container__block-column safety-stock-matrix__column"
          >
            <div
              v-if="rowIndex === 0 && safetyStock.nCols > 1"
              :key="`row-${rowIndex}-col-${colIndex}-xyzAxis`"
              class="tab-item-container__block-row safety-stock-matrix__row"
            >
              <div class="safety-stock-matrix__title safety-stock-matrix__title--xyz">
                {{ xyzAxis[colIndex] }}
              </div>
            </div>
            <MatrixCard
              :settings="item"
              :selected="isItemSelected(rowIndex, colIndex)"
              :label="getMatrixLabel(rowIndex, colIndex)"
              @change="toggleMatrixCardSelection(rowIndex, colIndex)"
              @click.native="handleMatrixCardClick(rowIndex, colIndex)"
            />
          </div>
        </transition-group>
      </transition-group>
    </transition>
    <transition
      v-else
      name="fade-down"
    >
      <SafetyStockForm
        :settings="safetyStock.matrix[0][0]"
        @change="handleFormChange"
      />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import modalIds from '@/config/shared/modalsId.config';
import cloneObject from '@/helpers/utils/cloneObject';
import { modal } from '@/mixins/modal';
import MatrixCard from '@/components/Settings/SafetyStock/MatrixCard.vue';
import SafetyStockEditModal from '@/components/Modals/Settings/SafetyStockEditModal.vue';
import SafetyStockForm from '@/components/Settings/SafetyStock/SafetyStockForm.vue';

export default {
  name: 'SafetyStock',
  components: {
    MatrixCard,
    SafetyStockEditModal,
    SafetyStockForm
  },
  mixins: [modal],
  data() {
    return {
      abcAxis: ['A', 'B', 'C', 'D'],
      xyzAxis: ['X', 'Y', 'Z', 'F'],
      selectedItems: [],
      modalIds
    };
  },
  computed: {
    ...mapState({
      safetyStock: state => state.settings?.settings?.safetyStock
    }),
    showMatrix() {
      return this.safetyStock && (this.safetyStock.nRows > 1 || this.safetyStock.nCols > 1);
    },
    title() {
      return this.showMatrix
        ? this.$t('WiInventorySettings.Ui.grpSafetyStockMatrix')
        : this.$t('WiInventorySettings.Ui.grpSafetyStock');
    }
  },
  watch: {
    safetyStock() {
      this.resetSelected();
    }
  },
  methods: {
    ...mapActions('settings', [
      'changeSettings',
      'changeSafetyStockSettings'
    ]),
    handleEditSelected() {
      this.openEditModal(this.selectedItems);
    },
    handleMatrixCardClick(rowIndex, colIndex) {
      if (this.selectedItems.length) {
        this.toggleMatrixCardSelection(rowIndex, colIndex);
      } else {
        this.openEditModal([{ abc: rowIndex, xyz: colIndex }]);
      }
    },
    toggleMatrixCardSelection(rowIndex, colIndex) {
      const isSelected = this.isItemSelected(rowIndex, colIndex);

      if (!isSelected) {
        this.selectedItems.push({ abc: rowIndex, xyz: colIndex });
      } else {
        this.selectedItems = this.selectedItems.filter(({ abc, xyz }) => abc !== rowIndex || xyz !== colIndex);
      }
    },
    handleFormChange(value) {
      this.changeSafetyStockSettings({
        keys: [{ abc: 0, xyz: 0 }],
        cell: value
      });
    },
    resetSelected() {
      this.selectedItems = [];
    },
    openEditModal(keys) {
      const { abc, xyz } = keys[0];
      const settings = cloneObject(this.safetyStock.matrix[abc][xyz]);
      const massEdit = keys.length > 1;
      const selectedItemsLabel = keys
        .sort((a, b) => a.abc === b.abc ? a.xyz - b.xyz : a.abc - b.abc)
        .map(({ abc, xyz }) => this.getMatrixLabel(abc, xyz));

      if (massEdit) {
        settings.color = null;
      }

      this.showModal(modalIds.SAFETY_STOCK_EDIT, {
        title: massEdit
          ? this.$t('Web.Modals.SafetyStockEdit.TitleAlternateSafetyStockForCategories', { 1: selectedItemsLabel.join(', ')})
          : this.$t('Web.Modals.SafetyStockEdit.TitleAlternateSafetyStockForCategory', { 1: selectedItemsLabel[0] }),
        massEdit,
        settings,
        applyCallback: (settings) => {
          this.changeSettings(
            {
              key: 'safetyStock',
              value: {
                keys,
                cell: settings
              }
            });
        }
      });
    },
    getMatrixLabel(rowIndex, colIndex) {
      const { nRows, nCols } = this.safetyStock;

      const abcLabel = nRows > 1 ? this.abcAxis[rowIndex] : '';
      const xyzLabel = nCols > 1 ? this.xyzAxis[colIndex] : '';

      return `${abcLabel}${xyzLabel}`;
    },
    isItemSelected(rowIndex, colIndex) {
      return this.selectedItems.some(({ abc, xyz }) => abc === rowIndex && xyz === colIndex);
    }
  }
};

</script>

<style lang="scss" scoped>
@import "@/style/components/settings";

.tab-item-container__block-title {
  line-height: 34px;
}

.safety-stock {
  &__title {
    align-items: center;
    justify-content: space-between;
  }

  &-matrix {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__title {
      text-align: center;
      align-self: center;

      color: $grey-60;
      @include body-3;

      &--xyz {
        width: 100%;
      }
    }

    &__row {
      gap: 8px;
    }

    &__column {
      width: 100%;
      min-width: 0;
      max-width: 350px;
      gap: 8px;
    }
  }
}
</style>