<template>
  <div
    class="tab-item-container"
    :class="{ 'disabled': disabled }"
  >
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('Settings.Ui.grpForecast') }}
      </span>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ horizonLabel }}
        </div>
        <SlValidate
          v-slot="{ invalid }"
          vid="horizonModel"
          rules="required|between:1,100"
        >
          <SlControlInput
            v-model="horizonModel"
            :is-invalid="invalid"
            :min="1"
            :max="100"
            @change="handleChange('horizon', null, $event)"
          />
        </SlValidate>
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ $t('Settings.Ui.lbIsSeasonal') }}
        </div>
        <SlValidate
          v-slot="{ invalid }"
          vid="seasonalThresholdModel"
          rules="required|between:0,1"
        >
          <SlControlInput
            v-model="seasonalThresholdModel"
            :is-invalid="invalid"
            :min="0"
            :max="1"
            :step="0.05"
            :fixed="2"
            @change="handleChange( 'seasonalTreshold', null, $event)"
          />
        </SlValidate>
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ $t('Settings.Ui.lbPriceElasticityThreshold') }}
        </div>
        <SlValidate
          v-slot="{ invalid }"
          vid="elasticityThresholdModel"
          rules="required|between:-1,0"
        >
          <SlControlInput
            v-model="elasticityThresholdModel"
            :is-invalid="invalid"
            :min="-1"
            :max="0"
            :step="0.01"
            :fixed="2"
            @change="handleChange('elasticityThreshold', null, $event)"
          />
        </SlValidate>
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ $t('Settings.Ui.lbMassTopDown') }}
        </div>
        <SlDropdown
          :target-label="$t(`Settings.MassTd.${massTopDownValue}`)"
          class="tab-item-container__select"
          placement="bottom"
          auto-size
        >
          <template #content>
            <SlDropdownOption
              v-for="option in massTopDownItems"
              :key="option.value"
              select-close
              @select="setMassTopDown(option.value)"
            >
              {{ option.text }}
            </SlDropdownOption>
          </template>
        </SlDropdown>
      </div>
      <div class="tab-item-container__block-row">
        <SlCheckbox
          v-model="allowNegativeSlopeModel"
          :label="$t('Settings.Ui.chkAllowNegativeSlope')"
        />
      </div>
      <span class="tab-item-container__block-sub-title">
        {{ $t('Settings.Ui.grpConfidenceLevels') }}
      </span>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ upperConfLevelLabel }}
        </div>
        <SlValidate
          v-slot="{ invalid }"
          vid="upperConfLevelModel"
          rules="required|between:0.1,99.9"
        >
          <SlControlInput
            v-model="upperConfLevelModel"
            :is-invalid="invalid"
            :min="0.1"
            :max="99.9"
            :step="0.1"
            :fixed="1"
            @change="handleChange('upperConfLevel', null, $event)"
          />
        </SlValidate>
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ lowerConfLevelLabel }}
        </div>
        <div class="tab-item-container__block-row">
          <SlValidate
            v-slot="{ invalid }"
            vid="lowerConfLevelModel"
            rules="required|between:0.1,99.9"
          >
            <SlControlInput
              v-model="lowerConfLevelModel"
              :is-invalid="invalid"
              :min="0.1"
              :max="99.9"
              :step="0.1"
              :fixed="1"
              @change="handleChange('lowerConfLevel', null, $event)"
            />
          </SlValidate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { access } from '@/mixins/access';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'ForecastTab',
  mixins: [access],
  props: {
    disabled: Boolean
  },
  computed: {
    ...mapState('settings', [
      'settings',
      'is_fetching'
    ]),
    ...mapGetters({
      period: 'project/period'
    }),
    massTopDownValue() {
      return this.settings?.massTopDown;
    },
    massTopDownItems() {
      return this.settings?.massTopDownItems.reduce((acc, item) => {
        acc.push({
          text: this.$t(`Settings.MassTd.${item}`),
          value: item
        });

        return acc;
      }, []);
    },
    horizonLabel() {
      return `${this.$t('Main.Horizon')}, ${this.$t(`Common.AfterComma.${this.period}`)}`;
    },
    upperConfLevelLabel() {
      return `${this.$t('Settings.Ui.lbUpperConfLevel')}, %`;
    },
    lowerConfLevelLabel() {
      return `${this.$t('Settings.Ui.lbLowerConfLevel')}, %`;
    },
    horizonModel: {
      get() {
        return this.settings?.horizon;
      },
      set(value) {
        this.settings.horizon = value;
      }
    },
    seasonalThresholdModel: {
      get() {
        return this.settings?.seasonalTreshold;
      },
      set(value) {
        this.settings.seasonalTreshold = value;
      }
    },
    elasticityThresholdModel: {
      get() {
        return this.settings?.elasticityThreshold;
      },
      set(value) {
        this.settings.elasticityThreshold = value;
      }
    },
    allowNegativeSlopeModel: {
      get() {
        return this.settings?.allowNegativeSlope;
      },
      set(value) {
        this.changeSettings({ key: 'allowNegativeSlope', value });
      }
    },
    upperConfLevelModel: {
      get() {
        return this.settings?.upperConfLevel;
      },
      set(value) {
        this.settings.upperConfLevel = value;
      }
    },
    lowerConfLevelModel: {
      get() {
        return this.settings?.lowerConfLevel;
      },
      set(value) {
        this.settings.lowerConfLevel = value;
      }
    }
  },
  methods: {
    ...mapActions('settings', [
      'changeSettings'
    ]),
    setMassTopDown(value) {
      this.changeSettings({ key: 'massTopDown', value });
    },
    handleChange(key, subKey, value) {
      this.changeSettings({ key, subKey, value: +value });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/settings";
</style>