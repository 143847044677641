<template>
  <div
    class="tab-item-container"
    :class="{ 'disabled': isTabDisabled }"
  >
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('Main.CustomizeColumnsSubmenus.ReplenishmentParams') }}
      </span>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ defaultLeadTimeLabel }}
        </div>
        <div class="tab-item-container__block-row">
          <SlValidate
            v-slot="{ invalid }"
            vid="defaultLeadTimeModel"
            rules="required|between:0,9999"
          >
            <SlControlInput
              v-model="defaultLeadTimeModel"
              :is-invalid="invalid"
              :min="0"
              :max="9999"
              @change="handleChange('inventory', 'defaultLeadTime', $event)"
            />
          </SlValidate>
        </div>
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ defaultOrderCycleLabel }}
        </div>
        <div class="tab-item-container__block-row">
          <SlValidate
            v-slot="{ invalid }"
            vid="defaultOrderCycleModel"
            rules="required|between:1,99"
          >
            <SlControlInput
              v-model="defaultOrderCycleModel"
              :is-invalid="invalid"
              :min="1"
              :max="99"
              :disabled="isDefaultOrderCycleDisabled"
              @change="handleChange('inventory', 'defaultOrderCycle', $event)"
            />
          </SlValidate>
        </div>
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ shelfDiscardLabel }}
        </div>
        <div class="tab-item-container__block-row">
          <SlValidate
            v-slot="{ invalid }"
            vid="shelfDiscardModel"
            rules="required|between:1,99"
          >
            <SlControlInput
              v-model="shelfDiscardModel"
              :is-invalid="invalid"
              :min="1"
              :max="99"
              @change="handleChange('inventory', 'shelfDiscard', $event)"
            />
          </SlValidate>
        </div>
      </div>
    </div>
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('WiInventorySettings.Ui.grpColumns') }}
      </span>
      <SlCheckbox
        v-model="demandViewModel"
        :label="$t('WiInventorySettings.Ui.chkDemandMaterial')"
        class="sl-checkbox"
      />
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ $t('Settings.Inventory.lbActual') }}
        </div>
        <SlValidate
          v-slot="{ invalid }"
          vid="actualSalesModel"
          rules="required|between:0,99"
        >
          <SlControlInput
            v-model="nActualModel"
            :is-invalid="invalid"
            :min="0"
            :max="99"
            @change="handleChange('nActual', null, $event)"
          />
        </SlValidate>
      </div>
    </div>
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('WiInventorySettings.Ui.grpPlan') }}
      </span>
      <div class="tab-item-container__block-row">
        <span class="tab-item-container__label">
          {{ $t('Settings.Inventory.lbType') }}
        </span>
        <SlControl
          v-model="orderStrategyModel"
          :options="orderStrategyOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  orderStrategyOptions,
  orderCycleUnitLabelByValue,
  orderCycleValues,
  orderStrategyKeys
} from '@/config/shared/settings.config';

export default {
  name: 'InventoryTab',
  props: {
    disabled: Boolean
  },
  data() {
    return {
      orderStrategyKeys
    };
  },
  computed: {
    ...mapState('settings', [
      'settings'
    ]),
    ...mapGetters({
      hasInventory: 'project/hasInv',
      period: 'project/period'
    }),
    orderCycleUnitLabelByValue() {
      return orderCycleUnitLabelByValue(this.period, this);
    },
    orderCycleUnitValue() {
      return this.settings?.orderCycleUnit;
    },
    orderCycleUnitLabel() {
      return this.orderCycleUnitLabelByValue[this.orderCycleUnitValue];
    },
    isTabDisabled() {
      return this.disabled || !this.hasInventory;
    },
    isDefaultOrderCycleDisabled() {
      return this.orderCycleUnitValue === orderCycleValues.LEADTIME;
    },
    orderStrategyOptions() {
      return orderStrategyOptions(this);
    },
    defaultLeadTimeLabel() {
      return `${this.$t('WiInventorySettings.Ui.lbLeadTime')}, ${this.$t('WiInventorySettings.Ui.lbLeadTime2')}`;
    },
    defaultOrderCycleLabel() {
      return `${this.$t('WiInventorySettings.Ui.lbOrderCycle')}, ${this.orderCycleUnitLabel}`;
    },
    shelfDiscardLabel() {
      return `${this.$t('WiInventorySettings.Ui.lbDiscard')}, %`;
    },
    defaultLeadTimeModel: {
      get() {
        return this.settings?.inventory?.defaultLeadTime;
      },
      set(value) {
        this.settings.inventory.defaultLeadTime = value;
      }
    },
    defaultOrderCycleModel: {
      get() {
        return this.settings?.inventory?.defaultOrderCycle ?? '';
      },
      set(value) {
        this.settings.inventory.defaultOrderCycle = value;
      }
    },
    shelfDiscardModel: {
      get() {
        return this.settings?.inventory?.shelfDiscard;
      },
      set(value) {
        this.settings.inventory.shelfDiscard = value;
      }
    },
    demandViewModel: {
      get() {
        return this.settings?.demandView;
      },
      set(value) {
        this.changeSettings({
          key: 'demandView',
          value
        });
      }
    },
    nActualModel: {
      get() {
        return this.settings?.nActual;
      },
      set(value) {
        this.settings.nActual = value;
      }
    },
    orderStrategyModel: {
      get() {
        return this.settings?.inventory?.orderStrategy;
      },
      set(value) {
        this.changeSettings({
          key: 'inventory',
          subKey: 'orderStrategy',
          value
        });
      }
    }
  },
  methods: {
    ...mapActions('settings', [
      'changeSettings'
    ]),
    handleChange(key, subKey, value) {
      this.changeSettings({ key, subKey, value: +value });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/settings";
</style>